<style></style>
<script>

import { with_acrf_token } from '@/methods';

export default {
  methods: {
    handleError(error){
      console.error(`[ERROR] ${error}`);
      this.error = true;
    },
    async disable2Fa() {
      let result = await this.$swal({
        icon: 'warning',
        text: this.$t('account.security.2fa.disable.disable_prompt'),
        title: this.$t('account.security.2fa.short_label_disable'),
        showCancelButton: true,
        confirmButtonText: this.$t('account.security.2fa.disable.title')
      });
      if(!result.isConfirmed) return;
      try {
        const acrfTokenRequest = await with_acrf_token();
        if (!acrfTokenRequest.status){
          this.$swal({
            icon: 'error',
            text: this.$t('error.server.generic.message')
          });
          throw new Error('[ERROR] Failed to request acrf token');
        }
        let payload = {
          acsrf_token: acrfTokenRequest.acsrf_token
        }
        const response = await fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/disable-2fa', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
        if (!response.ok){
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
        this.$emit('twoFADisabled');
      }catch(error){
        this.handleError(error);
      }
    }
  }
}
</script>


<template>
  <button class="btn btn-warning" @click="disable2Fa">
    <i class="fas fa-times"></i> {{ $t('account.security.2fa.disable.title') }}
  </button>
</template>